<template>
  <div class="checkout-container">
    <div class="checkout d-block d-lg-flex">
      <div class="checkout-left mb-5">
        <h1>Total Item</h1>
        <div v-for="product in parsedCart.items" :key="product.id">
          <div class="checkout-subtotal-are ">
            <div class="cheackout-subtotal-img">
              <div class="subtotal-img-area" style="height: 130px; width: 130px;">
                <img :src="product.image" alt="" height="130" width="130">
              </div>
              <div class="subtotal-item-title">
                <h2>{{ product.title }} x {{ product.quantity }}</h2>
                <!--                <p>{{ product.total_cal }} Cal</p>-->
              </div>
            </div>
            <div class="sub-total-price text-end">
              <h2>Sub-Total:</h2>
              <p>{{ product.total_price }}$</p>
            </div>
          </div>
          <div class="ingredients-area">
            <div class="ingredients-header d-flex align-items-center gap-1">
              <h1>Ingredients</h1>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10" fill="none">
                <path
                    d="M4.52667 9.75838C4.37176 9.9131 4.16177 10 3.94283 10C3.72389 10 3.5139 9.9131 3.35899 9.75838L0.242062 6.6431C0.0870725 6.48811 -2.30953e-09 6.2779 0 6.05871C2.30953e-09 5.83952 0.0870725 5.62931 0.242062 5.47432C0.397052 5.31933 0.607264 5.23226 0.826453 5.23226C1.04564 5.23226 1.25585 5.31933 1.41084 5.47432L3.11665 7.17957L3.11665 0.826187C3.11665 0.607068 3.20369 0.396924 3.35863 0.241984C3.51357 0.0870438 3.72371 0 3.94283 0C4.16195 0 4.3721 0.0870438 4.52704 0.241984C4.68198 0.396924 4.76902 0.607068 4.76902 0.826187V7.17957L6.47427 5.47432C6.55102 5.39758 6.64212 5.3367 6.74239 5.29517C6.84266 5.25363 6.95013 5.23226 7.05866 5.23226C7.16719 5.23226 7.27466 5.25363 7.37493 5.29517C7.4752 5.3367 7.56631 5.39758 7.64305 5.47432C7.7198 5.55106 7.78067 5.64217 7.8222 5.74244C7.86374 5.84271 7.88511 5.95018 7.88511 6.05871C7.88511 6.16724 7.86374 6.27471 7.8222 6.37498C7.78067 6.47525 7.7198 6.56636 7.64305 6.6431L4.52667 9.75838Z"
                    fill="#691209"/>
              </svg>
            </div>
            <div class="ingredients-menu">
              <div v-for="(items, category) in product.items" :key="category">
                <h6>{{ category }}</h6>
                <div class="menu-item d-flex justify-content-between">
                  <ul>
                    <li v-for="item in items" :key="item.title">{{ item.title }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="checkout-right">
        <div class="swi-1">
          <label class="toggle-format w-100">
            <input class="js-package-type-slider d-none" v-model="deliveryOption" type="checkbox"
                   @change="deliveryTypeChange"
                   style="pointer-events: auto;">
            <div class="slider-pkg d-flex w-100">
              <div :class="['option', 'webcontent-text', 'w-50', 'text-center', { 'active': !deliveryOption }]"
                   style="border-top-left-radius: 20px ">Delivery
              </div>
              <div :class="['option', 'webcontent-text', 'w-50', 'text-center', { 'active': deliveryOption }]"
                   style="border-top-right-radius: 20px ">Take-away
              </div>
            </div>

          </label>
        </div>
        <div v-if="deliveryOption === false" class="col-12">
          <div class="checkout-right-bottom">
            <h1>Delivery Location</h1>
            <h2>Delivery To</h2>
            <div class="checkout-order-location d-flex align-items-center gap-3">
              <div class="checkout-location-img">
                <img src="./Checkout-image/checkout-img-three.png" alt="">
              </div>
              <div class="checkout-set-locaton d-flex align-items-center gap-3">
                <GMapAutocomplete
                    v-model="addressField"
                    placeholder="This is a placeholder"
                    :options="{
                      location: { lat: 32.7560608, lng: -117.2139854 },
                      radius: 56327.4, // 35 miles in meters (1 mile = 1609.34 meters)
                      strictBounds: true,
                     }"
                    @place_changed="setPlace"
                >
                </GMapAutocomplete>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 15" fill="none">
                  <path
                      d="M13.4444 7.5C13.4444 10.5069 11.0069 12.9444 8 12.9444M13.4444 7.5C13.4444 4.49312 11.0069 2.05556 8 2.05556M13.4444 7.5H15M8 12.9444C4.99312 12.9444 2.55556 10.5069 2.55556 7.5M8 12.9444V14.5M8 2.05556C4.99312 2.05556 2.55556 4.49312 2.55556 7.5M8 2.05556V0.5M2.55556 7.5H1M10.3333 7.5C10.3333 8.7887 9.2887 9.83333 8 9.83333C6.7113 9.83333 5.66667 8.7887 5.66667 7.5C5.66667 6.2113 6.7113 5.16667 8 5.16667C9.2887 5.16667 10.3333 6.2113 10.3333 7.5Z"
                      stroke="#FFF" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>

          </div>
        </div>
        <div v-else class="col-12">
          <GMapMap
              :center="{ lat: 32.7558143, lng: -117.2109283 }"
              :zoom="15"
              style="width: 100%; height: 400px"
              :clickable="false" :draggable="false"
          >
            <GMapMarker
                :clickable="false" :draggable="false"
                :position="{ lat: 32.7558143, lng: -117.2109283 }"
            />
          </GMapMap>
        </div>
        <div v-if="loggedIn === 'False'" class="checkout-phone-number" style="padding: 2.5rem 2.5rem 0 2.5rem;">
          <h2>Phone Number</h2>
          <input class="mb-2" type="text" name="phone-number" placeholder="Enter your phone number"
                 v-model="phoneNumber">
          <span class="text-wrap" style="color: rgba(255,206,119,0.71);">*Create an account with given phone number to check your order history and their status.</span>
        </div>

        <div class="checkout-right-top">

          <h1>Amount</h1>
          <div class="checkout-bag-total d-flex align-items-center justify-content-between">
            <h1>Bag Total</h1>
            <p>{{ (this.totalPrice).toFixed(2) }}$</p>
          </div>
          <div v-if="loggedIn === 'True'" class="checkout-point-use d-flex align-items-center justify-content-between">
            <h1>Use Points <span>({{ user_points }})</span></h1>
            <div class="form-check form-switch">
              <input class="form-check-input point-input" type="checkbox" role="switch" v-model="usePoints">
            </div>
          </div>
          <div v-if="loggedIn === 'True'" class="checkout-point-use d-flex align-items-center justify-content-between">
            <h1 style="display: contents;" v-if="usePoints">Point Discount
              <span>{{ this.rewardDiscount.toFixed(2) }} $</span></h1>
            <h1 style="display: contents;" v-else><span>Point Discount</span> <span>0 $</span></h1>
          </div>
          <div class="checkout-promo-code d-flex justify-content-between">
            <input type="text" name="discount" placeholder="Enter promo Code">
            <button class="apply-btn" :class="{'cancel-btn': discountApplied}"
                    style="width: fit-content; padding: 1rem 0.3rem;"
                    @click="discountApplied ? cancelDiscount() : applyDiscount()">
              {{ discountApplied ? 'Cancel' : 'Apply' }}
            </button>
          </div>
          <span class="discount-error-msg text-warning">{{ this.errorMessage }}</span>
          <div class="checkout-right-sub-total d-flex align-items-center justify-content-between">
            <h1>Discount</h1>
            <p>{{ (this.couponDiscount + this.discountPrice + this.rewardDiscount).toFixed(2) }}</p>
          </div>
          <div class="checkout-Tax d-flex align-items-center justify-content-between">
            <h1>Delivery Charge</h1>
            <p>{{ deliveryFee }}</p>
          </div>
          <div class="checkout-total-pay d-flex align-items-center justify-content-between">
            <h1>Total Pay</h1>
            <h2>{{
                (this.totalPrice - (this.couponDiscount + this.discountPrice + this.rewardDiscount) + this.deliveryFee).toFixed(2)
              }}$</h2>
          </div>
        </div>
        <div class="checkout-right-center">
          <h1>Payment</h1>

          <input type="radio" name="payment_method" id="card" class="check-input" value="CARD"
                 v-model="selectedPaymentMethod">
          <label
              class="checkout-visa-card-area align-items-center d-flex align-items-center justify-content-between w-100"
              for="card">
            <div class="d-flex justify-content-between w-100  check-show">
              <div class="d-flex gap-4">
                <div class="checkout-visa-img">
                  <img src="./Checkout-image/checkout-img-two.png" alt="">
                </div>
                <div class="checkout-visa-card-code">
                  <h2>Credit / Debit Card</h2>
                </div>
              </div>
              <div class="payment-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                      d="M10 3.39691e-06C8.02219 3.39691e-06 6.08879 0.586493 4.4443 1.68531C2.79981 2.78412 1.51808 4.34591 0.761209 6.17317C0.00433282 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20.0011 8.68648 19.7432 7.38563 19.241 6.17189C18.7388 4.95814 18.0023 3.85532 17.0735 2.92652C16.1447 1.99772 15.0419 1.26117 13.8281 0.759002C12.6144 0.256839 11.3135 -0.00107967 10 3.39691e-06ZM10 17.6471C8.48756 17.6471 7.00908 17.1986 5.75152 16.3583C4.49397 15.518 3.51383 14.3237 2.93504 12.9264C2.35625 11.5291 2.20482 9.99152 2.49988 8.50813C2.79494 7.02475 3.52326 5.66217 4.59272 4.59271C5.66218 3.52325 7.02475 2.79494 8.50813 2.49988C9.99152 2.20482 11.5291 2.35625 12.9264 2.93504C14.3237 3.51383 15.518 4.49397 16.3583 5.75152C17.1986 7.00908 17.6471 8.48756 17.6471 10C17.6491 11.0048 17.4527 12.0001 17.0691 12.9288C16.6855 13.8575 16.1223 14.7013 15.4118 15.4118C14.7013 16.1223 13.8575 16.6855 12.9288 17.0691C12.0001 17.4527 11.0048 17.6491 10 17.6471ZM12.9412 6.88235L9.52941 11L7.17647 9.29412C6.64706 8.88235 5.94118 9 5.52941 9.52941C5.11765 10.0588 5.2353 10.7647 5.76471 11.1765C6.47059 11.7059 7.2353 12.2353 7.94118 12.7647C8.47059 13.1176 8.94118 13.6471 9.58824 13.7059C9.76471 13.7059 10 13.7059 10.1765 13.5882L10.5294 13.2353L13.1765 10.0588C13.6471 9.47059 14.1176 8.94118 14.5882 8.35294C14.9412 7.88235 15.0588 7.29412 14.6471 6.82353L14.4706 6.64706C14.0588 6.35294 13.3529 6.41177 12.9412 6.88235Z"
                      fill="white"/>
                </svg>
              </div>
            </div>
          </label>

          <input type="radio" name="payment_method" id="paypal" class="check-input" value="paypal"
                 v-model="selectedPaymentMethod">
          <label class="checkout-visa-card-area d-flex align-items-center justify-content-between w-100" for="paypal">
            <div class="d-flex justify-content-between align-center gap-4 check-show w-100">
              <div class="d-flex  gap-4">
                <div class="checkout-visa-img">
                  <img src="./Checkout-image/checkout-img-four.png" alt="">
                </div>
                <div class="checkout-visa-card-code">
                  <h2>Paypal</h2>
                </div>
              </div>
              <div class="payment-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                      d="M10 3.39691e-06C8.02219 3.39691e-06 6.08879 0.586493 4.4443 1.68531C2.79981 2.78412 1.51808 4.34591 0.761209 6.17317C0.00433282 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20.0011 8.68648 19.7432 7.38563 19.241 6.17189C18.7388 4.95814 18.0023 3.85532 17.0735 2.92652C16.1447 1.99772 15.0419 1.26117 13.8281 0.759002C12.6144 0.256839 11.3135 -0.00107967 10 3.39691e-06ZM10 17.6471C8.48756 17.6471 7.00908 17.1986 5.75152 16.3583C4.49397 15.518 3.51383 14.3237 2.93504 12.9264C2.35625 11.5291 2.20482 9.99152 2.49988 8.50813C2.79494 7.02475 3.52326 5.66217 4.59272 4.59271C5.66218 3.52325 7.02475 2.79494 8.50813 2.49988C9.99152 2.20482 11.5291 2.35625 12.9264 2.93504C14.3237 3.51383 15.518 4.49397 16.3583 5.75152C17.1986 7.00908 17.6471 8.48756 17.6471 10C17.6491 11.0048 17.4527 12.0001 17.0691 12.9288C16.6855 13.8575 16.1223 14.7013 15.4118 15.4118C14.7013 16.1223 13.8575 16.6855 12.9288 17.0691C12.0001 17.4527 11.0048 17.6491 10 17.6471ZM12.9412 6.88235L9.52941 11L7.17647 9.29412C6.64706 8.88235 5.94118 9 5.52941 9.52941C5.11765 10.0588 5.2353 10.7647 5.76471 11.1765C6.47059 11.7059 7.2353 12.2353 7.94118 12.7647C8.47059 13.1176 8.94118 13.6471 9.58824 13.7059C9.76471 13.7059 10 13.7059 10.1765 13.5882L10.5294 13.2353L13.1765 10.0588C13.6471 9.47059 14.1176 8.94118 14.5882 8.35294C14.9412 7.88235 15.0588 7.29412 14.6471 6.82353L14.4706 6.64706C14.0588 6.35294 13.3529 6.41177 12.9412 6.88235Z"
                      fill="white"/>
                </svg>
              </div>
            </div>
          </label>

          <div class="checkout-pay-btn">
            <input class="pay-now" v-if="selectedPaymentMethod != 'CARD'" type="button" name="" value="Pay Now"
                   @click="submitPayment">
            <payment-form v-if="selectedPaymentMethod === 'CARD'" :selectedPaymentMethod="selectedPaymentMethod"
                          :cart_id="parsedCart.id" :address="addressField" :coupon="coupon"
                          :total="totalPrice - (couponDiscount + discountPrice + rewardDiscount)"
                          :discount="couponDiscount + discountPrice + rewardDiscount"
                          :reward_discount="rewardDiscount" :loggedIn="loggedIn" :phoneNumber="phoneNumber"
                          :coupon_discount="couponDiscount + discountPrice" :delivery_fee="deliveryFee"
                          :stripe="this.stripe"></payment-form>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['cart', 'address', 'opening', 'closing', 'user_points', 'discount_per_point', 'stripe', 'user_logged_in'],
  data() {
    return {
      selectedPaymentMethod: '',
      parsedCart: JSON.parse(this.cart),
      coupon: null,
      totalPrice: 0,
      discountPrice: 0,
      couponDiscount: 0,
      rewardDiscount: 0,
      addressField: this.address,
      errorMessage: null,
      discountApplied: false,
      usePoints: false,
      phoneNumber: null,
      loggedIn: this.user_logged_in,
      deliveryOption: false,
      markers: [
        {
          position: {
            lat: 51.093048, lng: 6.842120
          },
        }
      ],
      distance: null,
      duration: null,
      deliveryFee: 8,
      fee: 0,
    };
  },

  created() {
    this.calculateTotalPrice();
    console.log(this.user_logged_in)
  },
  computed: {
    pointDiscount() {
      return this.usePoints
          ? this.user_points * this.discount_per_point
          : 0;
    },
    opening24() {
      return moment(this.opening, ['h a', 'hh a']).format('HH:mm');
    },
    closing24() {
      return moment(this.closing, ['h a', 'hh a']).format('HH:mm');
    },
    isWithinOpeningHours() {
      const now = moment();
      const opening1 = moment(this.opening24, 'HH:mm:ss');
      const closing1 = moment(this.closing24, 'HH:mm:ss');
      console.log(opening1)
      console.log(closing1)
      return now.isBetween(opening1, closing1);
    }
  },
  watch: {
    usePoints() {
      this.rewardDiscount = this.pointDiscount;
      this.calculateTotalPrice();
    },
    user_points() {
      this.rewardDiscount = this.pointDiscount;
      this.calculateTotalPrice();
    },
    discount_per_point() {
      this.rewardDiscount = this.pointDiscount;
      this.calculateTotalPrice();
    },
    phoneNumber() {
      this.phoneNumber = document.querySelector('input[name="phone-number"]').value
    },
    fee(newFee) {
      this.deliveryFee = this.fee
      console.log('Delivery fee updated:', newFee);
      this.calculateTotalPrice();
    }
  },
  methods: {
    updateDiscountPrice() {
      this.rewardDiscount = this.usePoints ? this.user_points * this.discount_per_point : 0;
    },
    calculateTotalPrice() {
      this.totalPrice = this.parsedCart.items.reduce((sum, product) => sum + product.total_price, 0);
    },
    async applyDiscount() {
      if (this.loggedIn == 'False' && !this.phoneNumber) {
        this.$toast.success('Enter phone number!', {
          duration: 4000, // Optional: duration in milliseconds
          position: 'bottom'
        });
      } else {
        try {
          const formData = new FormData();
          formData.append('coupon_code', document.querySelector('input[name="discount"]').value);
          if (this.phoneNumber) {
            formData.append('phone', this.phoneNumber);
          }
          const response = await fetch('/get-discount/', {
            method: 'POST',
            headers: {
              'X-CSRFToken': this.getCookie('csrftoken'),
            },
            body: formData,
          });

          if (response.ok) {
            const data = await response.json();
            if (data.amount_discount != null) {
              this.discountPrice = data.amount_discount
            } else {
              this.couponDiscount = this.totalPrice * (data.percentage_discount / 100)
            }
            this.coupon = document.querySelector('input[name="discount"]').value;
            this.discountApplied = true;
            this.errorMessage = null;
          } else {
            const data = await response.json();
            this.errorMessage = data.error
          }
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          this.successMessage = 'Looks like something went wrong!.';
        }
      }
    },
    deliveryTypeChange() {
      if (this.deliveryOption) {
        this.deliveryFee = 0;
        this.addressField = "Take-away";
      } else {
        this.addressField = 'none';
        this.setPlace()
      }
    },
    async setPlace(place) {

      if (!place || !place.geometry || !place.geometry.location) {
        this.deliveryFee = 8; // Default fee if no place is selected
        return;
      }
      this.addressField = place.formatted_address;
      // Extract the lat/lng from the place object
      const destinationLat = place.geometry.location.lat();
      const destinationLng = place.geometry.location.lng();

      const origin = '32.7560608,-117.2139854';
      const destination = `${destinationLat},${destinationLng}`;

      const apiUrl = `/get-distance-matrix?origin=${origin}&destination=${destination}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.status === 'OK') {
          const results = data.rows[0].elements[0];

          if (results.status === 'OK') {
            const distanceText = results.distance.text; // e.g., "5.2 mi"
            const distanceValue = parseFloat(distanceText); // Convert distance to a float
            console.log(distanceValue)
            this.distance = distanceValue;
            this.duration = results.duration.text;

            // Determine the delivery fee based on the distance
            if (distanceValue <= 5) {
              this.fee = 8;
            } else if (distanceValue <= 10) {
              this.fee = 10;
            } else if (distanceValue <= 15) {
              this.fee = 12;
            } else if (distanceValue <= 20) {
              this.fee = 15;
            } else if (distanceValue <= 25) {
              this.fee = 18;
            } else if (distanceValue <= 30) {
              this.fee = 22;
            } else if (distanceValue <= 35) {
              this.fee = 26;
            }
          } else {
            console.error('Error calculating distance:', results.status);
          }
        } else {
          console.error('Error with Distance Matrix API:', data.error_message);
        }
      } catch (error) {
        console.error('Request failed:', error);
      }
    },
    cancelDiscount() {
      this.discountPrice = 0;
      this.couponDiscount = 0;
      this.coupon = null;
      this.discountApplied = false;
      this.errorMessage = null;
    },
    async submitPayment() {
      if (!this.addressField || this.addressField === 'None') {
        this.errorMessage = 'Please provide a valid address.';
        return;
      }

      if (this.loggedIn === 'False') {
        const phoneNumber = document.querySelector('input[name="phone-number"]').value;
        this.phoneNumber = phoneNumber
        if (!phoneNumber) {
          this.errorMessage = 'Please enter a valid phone number.';
          return;
        }
      }
      const formData = new FormData();
      formData.append('amount', this.totalPrice - (this.couponDiscount + this.discountPrice + this.rewardDiscount));
      formData.append('coupon', this.coupon);
      formData.append('cart_id', this.parsedCart.id);
      formData.append('address', this.addressField);
      formData.append('discount', this.couponDiscount + this.discountPrice + this.rewardDiscount);
      formData.append('coupon_discount', this.couponDiscount + this.discountPrice);
      formData.append('reward_discount', this.rewardDiscount);
      formData.append('delivery_fee', this.deliveryFee);
      const paymentMethod = document.querySelector('input[name="payment_method"]:checked').value;
      formData.append('payment_method', paymentMethod);
      if (this.loggedIn === 'False') {
        formData.append('phone_number', this.phoneNumber)
      }

      try {
        const csrftoken = this.getCookie('csrftoken');
        const response = await fetch('../pay-now/?type=' + paymentMethod, {
          method: 'POST',
          headers: {
            'X-CSRFToken': csrftoken,
          },
          body: formData
        });

        if (response.ok) {
          const data = await response.json();
          window.location.href = data.approval_url; // Change this to your desired URL
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        this.successMessage = 'Looks like something went wrong!.';
      }
    },
    getCookie(name) {
      const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
      return cookieValue ? cookieValue.pop() : '';
    },
  }

};
</script>

<style scoped>
.form-check-input:checked {
  background-color: #ffa700b5;
  border-color: #ffa700b5;
}

.point-input {
  width: 3rem !important;
  height: 1.5rem;
}

.checkout-container {
  padding: 80px;
  width: 100%;
}

.checkout {
  width: 100%;
  gap: 52px;
  justify-content: center;
}

.checkout-left {
  width: 40% !important;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #E7EAF3;
  background: #FFF;
}

.checkout-left > h1 {
  color: #1E1E1E;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 100% */
  margin-bottom: 40px;
}

.checkout-subtotal-are {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cheackout-subtotal-img {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subtotal-img-are {
  width: 130px;
  height: 130px;
}

.subtotal-img-area img {
  width: 100%;
  height: 100%;
}

.subtotal-item-title h2 {
  color: #691209;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
}

.subtotal-item-title p {
  color: #97A2B0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 100% */
  margin-bottom: 0;
}

.sub-total-price h2 {
  color: #691209;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 100% */
  margin-bottom: 12px;

}

.sub-total-price p {
  color: #691209;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 100% */
  margin-bottom: 12px;
}

.ingredients-header {
  margin-bottom: 20px;
}

.ingredients-header h1 {
  color: #691209;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 4px;
}

.ingredients-menu {
  padding-bottom: 40px;
  border-bottom: 1px solid #81161651;
}

.menu-item li,
.menu-item p {
  color: #691209;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 100% */
}

.checkout-right {
  border-radius: 20px;
  background-color: #691209;
  width: 60% !important;
}

.checkout-right-top {
  padding: 40px;
  border-bottom: 1px solid white;
}

.checkout-right-top > h1 {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 100% */
  margin-bottom: 40px;
}

.checkout-bag-total h1 {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-bag-total p {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-point-use {
  margin: 20px 0;
}

.checkout-point-use h1 {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-point-use select {
  width: fit-content;
}

.checkout-promo-code {
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #FFF;
}

.checkout-promo-code input, .checkout-phone-number input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  /* 100% */
  margin-right: 10px;
}

.checkout-phone-number h1 {
  color: white;
  font-size: 2rem;
}

.checkout-phone-number input {
  border-bottom: 1px solid white;
  padding: 10px;
}

.checkout-promo-code h3 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-right-sub-total {
  margin-bottom: 20px;
}

.checkout-right-sub-total h1 {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-right-sub-total p {
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-Tax {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px dashed white;
}

.checkout-Tax h1 {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-Tax p {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-total-pay h1 {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-total-pay h2 {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 100% */
  margin-bottom: 0;
}

.checkout-right-center {
  padding: 40px;
  border-bottom: 1px solid white;
}

.checkout-right-center h1 {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 100% */
  margin-bottom: 40px;
}

.checkout-visa-card-area {
  border-radius: 14px;
  border: 1px solid #691209;
  gap: 20px;
  margin-bottom: 40px;
  padding: 12px;
  cursor: pointer;
  transition: .5s;
}

.check-input {
  display: none;
}

.payment-icon {
  display: none;
}

.payment-icon svg {
  margin-top: 12px;
}

/* Targeting the checked input and the sibling label */
.check-input:checked + .checkout-visa-card-area {
  background: #7B231A;
}

.check-input:checked + .checkout-visa-card-area .payment-icon {
  display: inline-block;
}

.checkout-visa-card-area:hover {
  background: #7B231A;
}

.checkout-visa-card-code h2 {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  /* 200% */
  letter-spacing: 0.5px;
  margin-bottom: 0;
}


.checkout-add-payment h2 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.checkout-right-bottom {
  padding: 40px;
}

.checkout-right-bottom h1,
.checkout-phone-number h2 {
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  /* 71.875% */
  margin-bottom: 40px;
}

.checkout-right-bottom > h2 {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.checkout-location-img {
  width: 60px;
  height: 60px;
}

.checkout-order-location h2 {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.checkout-order-location input {
  background: transparent;
  width: 100%;
  padding: 10px;
  color: white;
  border: none;
  outline: none;
  font-size: 16px;
}

.checkout-set-locaton {
  width: 100%;
}

.checkout-set-locaton input {
  border-bottom: 1px solid white;
}

.checkout-pay-btn {
  margin-top: 40px;
}

.checkout-pay-btn input {
  padding: 15px;
  width: 100%;
  color: white;
  border: 2px solid white;
  border-radius: 15px;
  background: #ffa700b5;

  font-size: 14px;
  font-weight: 600;
  transition: 0.5s;
}

.checkout-pay-btn input:hover {
  background: #ffa700;
}

.checkout-container input::placeholder {
  color: rgb(255, 255, 255);
}


.webcommerce-text, .webcontent-text {
  padding: 20px !important;
  cursor: pointer;
  font-size: 24px !important;
  color: white;
  font-weight: 500;
}


.option.active {
  border-bottom: 1px solid white;
  background: #ffa700b5;
}


@media only screen and (max-width: 991px) {
  .checkout-left {
    width: 100% !important;
  }

  .checkout-right {
    width: 100% !important;
  }

  .checkout-container {
    padding: 40px;
    width: 100%;
  }
}

@media only screen and (max-width: 599px) {
  .cheackout-subtotal-img {
    display: block;
  }

  .subtotal-item-title {
    margin-bottom: 0.5rem;
  }

  .checkout-subtotal-are {
    display: block;
  }

  .sub-total-price {
    display: flex;
    gap: 0.5rem;
    text-align: start !important;
    margin-top: 1rem;
  }

  .checkout-container {
    padding: 1.5rem;
  }

  .checkout-container .checkout-left, .checkout-container .checkout-right {
    padding: 1.5rem;
  }

  .checkout-right-top, .checkout-right-center, .checkout-right-bottom {
    padding: 1.5rem 0 1.5rem 0;
  }

  .checkout-right {
    width: 100% !important;
  }
}
</style>
